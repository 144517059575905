import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderByService } from '@services/order-by.service';
import { roundOffMinutes } from '@common/date-extension';
import { Subcontractor } from '@models/subcontractor';
import { SubcontractorOrder, SubcontractorOrderListItem, SubcontractorOrderState, SubcontractorOrderTrips } from '@models/subcontractor-order';
import { SubcontractorOrderService } from '@services/subcontractor-order.service';
import { ListType } from '@models/list-type';
import { ListViewService } from '@services/list-view.service';
import { OrderType } from '@models/order-type';
import { SubContractorListSearch } from '@models/list-search';

@Component({
  selector: 'app-subcontractor-order-list',
  templateUrl: './subcontractor-order-list.component.html',
  styleUrls: ['./subcontractor-order-list.component.scss']
})
export class SubcontractorOrderListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() subcontractor: Subcontractor;
  @Input() state: number = 1;

  paramsub: Subscription;
  subcontractorOrdersList: SubcontractorOrderListItem[];
  subcontractorOrderTripsCount: number;
  ListType = ListType;
  OrderType = OrderType;
  stateDescription = $localize`:Beställd@@subcontractor-order-list.state-ordered:Beställd`;

  subcontractorOrderServiceSubscription: Subscription;

  pageSize: number;
  currentPage: number = 1;

  constructor(
    private orderByService: OrderByService,
    private listViewService: ListViewService,
    private subcontractorOrderService: SubcontractorOrderService,
  ) { }


  ngOnInit() {
    this.subcontractorOrderTripsCount = this.getSubcontractorOrdersList();
  }

  ngOnChanges() {
    this.currentPage = 1;
    this.subcontractorOrderTripsCount = this.getSubcontractorOrdersList();
  }

  ngOnDestroy() {
    if (this.subcontractorOrderServiceSubscription)
      this.subcontractorOrderServiceSubscription.unsubscribe();
  }

  getSubcontractorOrdersList(): number {
    this.pageSize = this.listViewService.pageSize;

    if (this.subcontractor?.id) {
      const listSearch = new SubContractorListSearch();
      listSearch.subcontractorid = this.subcontractor.id;
      listSearch.listType = this.state;
      listSearch.page = this.currentPage;
      listSearch.pageSize = this.pageSize;

      this.subcontractorOrderServiceSubscription = this.subcontractorOrderService
        .getSubcontractorOrdersList(listSearch)
        .subscribe(resp => {
          let response: SubcontractorOrderTrips = resp.body;
          this.subcontractorOrdersList = response.items;

          roundOffMinutes([
            ...this.subcontractorOrdersList.flatMap(ai => ai.startTripStop.passTime),
            ...this.subcontractorOrdersList.flatMap(ai => ai.endTripStop.passTime),
            ...this.subcontractorOrdersList.flatMap(ai => ai.currentState.updated)
          ]);

          this.subcontractorOrdersList = this.orderByService.transform(
            this.subcontractorOrdersList,
            'subcontractorOrder.startTripStop.passTime',
            false
          );

          this.subcontractorOrderTripsCount = response.totalItems;
        });

      return this.subcontractorOrderTripsCount;
    }
  }

  getLinkToDriveOrderTrip(orderId: number, driveOrderId: number, tripId: number) : string {
    return `${window.location.origin}/order-new/order/${orderId?.toString()}/drive-order#${driveOrderId?.toString()}_${tripId?.toString()}`
  }

  selectedPageSize(event: number) {
    this.listViewService.pageSize = event;
    this.currentPage = 1;
    this.subcontractorOrderTripsCount = this.getSubcontractorOrdersList()
  }

  pageChanged(event: number) {
    this.currentPage = event;
    this.subcontractorOrderTripsCount = this.getSubcontractorOrdersList()
  }
}
